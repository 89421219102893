.cust_continer{
    width: 80%;
    min-height: 50vh;
    animation: mymove 1s ease-in-out;
}
.warn_continer{
    width: 40%;
    height: 250px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    animation: mymove1 0.4s ease-in-out;
}
.clean_continer{
    width: 40%;
    height: 300px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    animation: mymove1 0.4s ease-in-out;
}
.customise_img_cont{
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 7px;
}

.item_container{
  &:hover{
    scale: 1.015;
    box-shadow: 0.5px 0.5px 10px rgb(125, 125, 128) , -0.5px -0.5px 10px rgb(125, 125, 128);
  }
}
.cust_opt{
  height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.customise_img{
  width: 100%;
 
}

.scrollableDiv{
  overflow-y: auto;
  height: 50vh;
}
.scrollableDiv::-webkit-scrollbar {
  width: 2px; /* Set the width of the scrollbar */
}

/* Track */
.scrollableDiv::-webkit-scrollbar-track {
  background: rgb(97, 51, 51); /* Color of the scrollbar track */
}

/* Handle */
.scrollableDiv::-webkit-scrollbar-thumb {
  background: greenyellow; /* Color of the scrollbar handle */
}

/* Handle on hover */
.scrollableDiv::-webkit-scrollbar-thumb:hover {
  background: greenyellow; /* Color of the scrollbar handle when hovered */
}

.list-container {
  width: 100%; /* Full width of the screen */
  height: 80vh; /* Full width of the screen */
  overflow-x: auto; /* Allows horizontal scrolling if necessary */
}

.list-container ol {
  columns: 1; /* Creates 2 columns */
  column-width: 100%; /* Space between columns */
  list-style-position: inside;
  padding-left: 10px;
  margin: 0;
}

.list-container li {
  break-inside: avoid; /* Prevents items from breaking across columns */
  padding: 10px 0;
  box-sizing: border-box;
}


@keyframes mymove {
    from {top: -220px;}
    to {top: 0px;}
  }
@keyframes mymove1 {
    from {left: -500px;}
    to {left: 0px;}
  }
  @media only screen and (max-width: 600px) {
    .cust_continer{
      width: 80%;
    }
    .warn_continer{
      width: 80%;
    }
    .clean_continer{
      width: 80%;
    }
    .customise_img_cont{
      width: 30%;
      height: auto;
      overflow: hidden;
      border-radius: 7px;
  }
  }