.chatbot-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .Chat-header {
    text-align: center;
    margin-top: 50px;
  }
  .chat-container{
    position: relative;
    z-index: 1000000;
  }
  .floating-icon {
    background-color: #EBF8F2; /* Set your desired background color */
    border-radius: 50%; /* Make it circular */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: 5px;
  }
  