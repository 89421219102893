.text-center {
  align-items: center;
}

.text-edit {
  color: #0071ad;
}

.text-danger {
  color: #a62636;
}

.pointer {
  cursor: pointer;
}




