/* src/Loader.css */
.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: black;
  }
  